import { CheckCircle } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Fab,
  Grid,
  Link,
  MenuItem,
  MenuList,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CountDownCard from "src/components/CountDownCard";
import Dialogs from "src/components/Dialogs";
import FormBuilder from "src/components/formBuilder/FormBuilder";
import { media_url } from "src/coreConfig";
import useBlog from "src/hooks/useBlog";
import useCorporate from "src/hooks/useCorporate";
import useWindowSize from "src/hooks/useWindowSize";
import { useSelector } from "src/store";
import ExchangeValues from "../module/ExchangeValues";

const pageViews = {
  page: "page",
  procedures: "procedures",
  certificates: "certificates",
  payments: "payments",
  solvencies: "solvencies",
};
const BlogIndex = () => {
  const { pages, banners, menu, snippets, loaded } = useSelector(
    (state) => state.blog
  );
  const { currentCustomer } = useSelector((state) => state.customers);
  const { procedures, departments } = useSelector((state) => state.procedures);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { getPosts } = useBlog();
  const { t } = useTranslation();
  const initialized = React.useRef(false);
  const { loadProceduresList } = useCorporate();
  const [blogState, setBlogState] = React.useState({
    page: 1,
    posts: [],
    view: "page",
    menu: null,
    selectedDepartment: null,
    loading: false,
    currentBanner: 0,
  });
  const { posts, page, loading, currentBanner } = blogState;

  const width = useWindowSize().width;

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getPosts(page).then((data) => {
        setBlogState((preState) => ({
          ...preState,
          posts: data,
          loading: false,
        }));
      });
      loadProceduresList();
    }
  }, []);

  React.useEffect(() => {}, [currentBanner]);

  const onlineServices = [
    {
      icon: "/static/icons/tramites.png",
      text: "Trámites",
      onClick: () =>
        setBlogState((preState) => ({
          ...preState,
          view: pageViews.procedures,
        })),
    },
    {
      icon: "/static/icons/certificados.png",
      text: "Certificados",
      onClick: () =>
        setBlogState((preState) => ({
          ...preState,
          view: pageViews.certificates,
        })),
    },
    {
      icon: "/static/icons/pagos.png",
      text: "Pagos",
      onClick: () =>
        setBlogState((preState) => ({ ...preState, view: pageViews.payments })),
    },
    {
      icon: "/static/icons/solvencias.png",
      text: "Solvencias",
      onClick: () =>
        setBlogState((preState) => ({
          ...preState,
          view: pageViews.solvencies,
        })),
    },
  ];

  if (!pages || !posts || !banners || !menu) return null;

  return (
    <>
      <Dialogs
        value={blogState.view}
        onClose={() =>
          setBlogState((preState) => ({
            ...preState,
            view: "page",
            selectedDepartment: null,
          }))
        }
        dialogs={[
          {
            value: pageViews.procedures,
            title: t("Trámites"),
            size: "md",
            body: (
              <>
                <Grid container>
                  <Autocomplete
                    options={departments}
                    onChange={(e, value) => {
                      setBlogState((preState) => ({
                        ...preState,
                        selectedDepartment: value,
                      }));
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Departamento" />
                    )}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                  />
                </Grid>

                <Grid container>
                  {blogState.selectedDepartment && (
                    <Grid item xs={12}>
                      <MenuList dense>
                        {procedures
                          .filter(
                            (p) =>
                              p.department_id ===
                              blogState.selectedDepartment.id
                          )
                          .map((procedure) => (
                            <MenuItem
                              key={procedure.id}
                              onClick={() => {
                                window.open(
                                  `/${currentCustomer.shortname}/corporate/procedure/preview/${procedure.id}`,
                                  "_blank"
                                );
                                setBlogState((preState) => ({
                                  ...preState,
                                  view: "page",
                                  selectedDepartment: null,
                                }));
                              }}
                            >
                              {procedure.name}
                            </MenuItem>
                          ))}
                      </MenuList>
                    </Grid>
                  )}
                  {!blogState.selectedDepartment && (
                    <Grid item xs={12}>
                      <MenuList dense>
                        {procedures.map((procedure) => (
                          <MenuItem
                            key={procedure.id}
                            onClick={() => {
                              window.open(
                                `/${currentCustomer.shortname}/corporate/procedure/preview/${procedure.id}`,
                                "_blank"
                              );
                              setBlogState((preState) => ({
                                ...preState,
                                view: "page",
                                selectedDepartment: null,
                              }));
                            }}
                          >
                            {procedure.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Grid>
                  )}
                </Grid>
              </>
            ),
          },
          {
            value: pageViews.certificates,
            title: t("Certificados"),
            size: "md",
            body: (
              <>
                <FormBuilder
                  title={t("Validar Certificado")}
                  onSubmit={(data) => {
                    window.open(
                      `/certificate/validation/${data.id}/?register_id=${data.register_id}`,
                      "_blank"
                    );
                  }}
                  onCancel={() => {
                    setBlogState((preState) => ({
                      ...preState,
                      view: "page",
                    }));
                  }}
                  buttonsProps={{
                    submit: {
                      variant: "contained",
                      color: "success",
                      label: t("Validar"),
                      icon: <CheckCircle />,
                    },
                  }}
                  fieldSet={[
                    {
                      id: "id",
                      type: "text",
                      label: t("Número de Certificado"),
                    },
                    {
                      id: "register_id",
                      type: "text",
                      label: t("Número de Registro"),
                    },
                  ]}
                />
              </>
            ),
          },
        ]}
      />
      <div
        style={{
          backgroundImage: `url(${media_url(banners[currentBanner].image)})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: width,
          // relation to banner 1200x500
          // 100% * 500 / 1200 = 41.66666666666667%
          height: width * 0.416 + "px",
          margin: "-1rem !important",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: 0,
          top: "2rem",
          right: 0,
          bottom: 0,
          zIndex: 0,
          transition: "background-image 0.5s",
          // prevent to show scroll
          overflow: "hidden !important",
          scroll: "no",
        }}
      >
        {/* a div layer to opacite gradient */}
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
            background: "linear-gradient(rgb(56 56 56) 0%, rgb(0 0 0) 100%)",
            opacity: 0.9,
            transition: "opacity 0.5s",
          }}
        ></div>
        {/* now carrousel  */}
        <div
          style={{
            zIndex: 2,
            width: "100%",
            marginTop: "3rem",
            position: "relative",
            borderRadius: "10px",
            boxShadow: "0px 0px 1px rgb(0 0 0 / 50%)",
            overflow: "hidden",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              padding: "0 !important",
              paddingTop: 2,
            }}
          >
            <Carousel
              width="100%"
              height={isSmall ? 150 : 400}
              autoPlay={true}
              animation="slide"
              onChange={(index) => {
                setBlogState((preState) => ({
                  ...preState,
                  currentBanner: index,
                }));
              }}
            >
              {banners.map((banner) => (
                <Container
                  key={banner.id}
                  sx={{
                    backgroundImage: `url(${media_url(banner.image)})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    cursor: banner.link ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (banner.link) {
                      window.open(banner.link, "_blank");
                    }
                  }}
                >
                  <Box sx={{ minWidth: 450 }}></Box>
                </Container>
              ))}
            </Carousel>
          </Container>
        </div>
      </div>
      <Box
        sx={{
          flexGrow: 1,

          marginTop: width * 0.43 + 30 + "px",
        }}
      >
        <CountDownCard
          disabled
          title={"Iniciamos el 2025 en la era de la transformación digital."}
          time={"2025-01-13T15:37:25-0400"}
          action={{ label: "H", onClick: () => {} }}
          bottom={
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", marginBottom: 2, fontStyle: "italic" }}
            >
              Juntos construimos el mejor San Carlos.
            </Typography>
          }
        />

        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "16px",
              paddingTop: "30px",

              // background: "linear-gradient(90deg, #21cbf4 0%, #8b18fa 100%)",
              background:
                "linear-gradient(90deg, rgb(8 8 8) 0%, rgb(60 0 118) 100%)",
              color: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 0px 1px rgb(0 0 0 / 50%);",
            }}
          >
            {/* La Alcaldía se Moderniza: ¡Transformación Digital en Marcha! */}
            {currentCustomer.shortname === "sancarlos" && (
              // set a count down timer until 2025-01-03
              <>
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    textAlign: "center",
                  }}
                >
                  {t(
                    "Iniciamos el 2025 en la era de la transformación digital. "
                  )}
                </Typography>

                <Button
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    background:
                      "linear-gradient(90deg, #ff8a00 0%, #e52e71 100%)",
                    color: "#FFFFFF",
                    // hover effect
                    "&:hover": {
                      background:
                        "linear-gradient(90deg, #e52e71 0%, #ff8a00 100%)",
                    },
                  }}
                  onClick={() => {
                    window.open(`/${currentCustomer.shortname}`, "_blank");
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 2,
                      fontSize: "3rem !important",
                    }}
                  >
                    ¡Ingresar!
                  </Typography>
                </Button>

                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    fontStyle: "italic",
                  }}
                >
                  Juntos construimos el mejor San Carlos.
                </Typography>
                <Typography variant="h3" sx={{ marginBottom: 2 }}>
                  {t("¡Transformación Digital en Marcha!")}
                </Typography>

                <Typography
                  variant="h3"
                  sx={{ marginBottom: 2, textAlign: "center" }}
                >
                  <span role="img" aria-label="celebration">
                    🎉
                  </span>{" "}
                  {t("Durante el mes de enero 2025 se extenderán ")}
                  <br />
                  {t(
                    "los plazos para disfrutar de los beneficios tributarios."
                  )}{" "}
                  {/* emoji de festejo */}
                  <span role="img" aria-label="celebration">
                    🎉
                  </span>
                </Typography>
              </>
            )}
            <span style={{ margin: 10 }}></span>
            {currentCustomer.shortname !== "sancarlos" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(`/${currentCustomer.shortname}`, "_blank");
                }}
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  padding: "10px 20px",
                  borderRadius: "50px",
                  background:
                    "linear-gradient(90deg, #ff8a00 0%, #e52e71 100%)",
                  // hover effect
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #e52e71 0%, #ff8a00 100%)",
                  },
                }}
              >
                {t("Ingresar al Sistema Online")}
              </Button>
            )}
            {currentCustomer.shortname === "losguayos" && (
              <>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    window.open(
                      `https://aseo.alcaldialosguayos.org/panel/`,
                      "_blank"
                    );
                  }}
                >
                  {t("Ingresar al Aseo Urbano")}
                </Button>
              </>
            )}
          </Grid>
        </Grid>

        {/* here Descubre lo que puedes hacer en línea sin salir de tu casa" Trámites, Certificados, Pagos, Solvencias */}
        {/* each item with icon and text in Grid layout */}
        <Grid
          container
          sx={{
            marginTop: 2,
            padding: 2,
            background: "#f5f5f5",
            borderRadius: "10px",
            boxShadow: "0px 0px 1px rgb(0 0 0 / 50%)",
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{
                margin: 2,
                fontWeight: "bold",
                textAlign: "center",
                // gradient text
                background: "linear-gradient(90deg, #21cbf4 0%, #8b18fa 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {t("Descubre lo que puedes hacer en línea sin salir de tu casa")}
            </Typography>
            <Grid container spacing={2}>
              {onlineServices.map((service) => (
                <Grid item key={service.text} xs={12} sm={6} md={4} lg={3}>
                  <OnlineServiceCard
                    icon={service.icon}
                    text={service.text}
                    onClick={service.onClick}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {snippets
          .filter((s) => s.position === "top")
          .map((snippet) => (
            <div
              key={snippet.id}
              dangerouslySetInnerHTML={{ __html: snippet.html }}
              style={{ margin: 10 }}
            />
          ))}

        {/* if posts stiked exists display in horizontal scroll */}
        {posts.filter((p) => p.sticky).length > 0 && (
          <Grid
            container
            sx={{
              marginTop: 2,
              padding: 2,
              // dark gradient background
              background:
                "linear-gradient(90deg, rgb(8 8 8) 0%, rgb(60 0 118) 100%)",

              borderRadius: "10px",
              boxShadow: "0px 0px 1px rgb(0 0 0 / 50%)",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ margin: 2, color: "white" }}>
                {t("Destacados")}
              </Typography>
              <Grid
                container
                // display in horizontal scroll

                sx={{
                  overflowX: "auto",
                  display: "flex",
                  flexDirection: "column",
                  whiteSpace: "nowrap",
                  width: "100%",
                  height: 260,
                }}
              >
                {posts
                  .filter((p) => p.sticky)
                  .map((post) => (
                    <Grid
                      item
                      key={post.id}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={() => {
                        window.open(
                          `/${currentCustomer.shortname}/blog/${post.id}`,
                          "_blank"
                        );
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <div
                        style={{
                          margin: 10,
                          position: "relative",
                          height: 200,
                          width: 200,
                        }}
                      >
                        <img
                          // src={media_url(post.image)}
                          src={media_url(post.image)}
                          alt={post.title}
                          style={{
                            width: "100%",
                            height: 200,
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // gradient increase opacity at bottom

                            background:
                              "linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.8) 100%)",
                            padding: 5,
                            color: "white",
                            textOverflow: "ellipsis",
                            textWrap: "wrap",
                            overflow: "hidden",
                            height: 60,
                            borderRadius: "0 0 10px 10px",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {post.title}
                          </Typography>
                        </div>
                      </div>
                      {/* <BlogPostCard post={post} variant="a" /> */}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          spacing={2}
          // centered
          sx={{
            padding: {
              xs: 1,
              md: 2,
            },
            marginTop: {
              xs: 1,
              md: 2,
            },
            marginBottom: 2,
            display: "flex",
            flexDirection: isSmall ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {snippets
            .filter((s) => s.position === "main_page_middle")
            .map((snippet) => (
              <div
                key={snippet.id}
                dangerouslySetInnerHTML={{ __html: snippet.html }}
                style={{ margin: 10 }}
              />
            ))}

          <Grid item sx={{ width: 0, height: 0, overflow: "hidden" }}>
            <Link href="#" target="_blank">
              <Fab variant="extended" color="secondary">
                {" "}
              </Fab>
            </Link>
          </Grid>
          <Grid item sx={{ width: 0, height: 0, overflow: "hidden" }}>
            <Fab variant="extended" color="success"></Fab>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sx={{ marginTop: "30px" }} md={8}>
            {/* post in grid */}
            <Grid container>
              {posts
                .filter((p) => !p.sticky)
                .map((post) => (
                  <Grid item key={post.id} xs={12} md={6}>
                    <BlogPostCard post={post} variant="a" />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* side snippets */}
            <BlogSideBar />

            <br />
            <ExchangeValues />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const BlogPostCard = ({ post, variant }) => {
  const { t } = useTranslation();
  const { currentCustomer } = useSelector((state) => state.customers);
  const navigate = useHistory();

  const handleClick = () => {
    navigate.push(`/${currentCustomer.shortname}/blog/${post.id}`);
  };

  if (variant === "row") {
    // return card with media on left and text on right
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          margin: 2,
          boxShadow: "0px 0px 1px rgb(0 0 0 / 50%);",
          cursor: "pointer",
          borderRadius: "10px",
        }}
        onClick={handleClick}
      >
        <img
          src={media_url(post.image)}
          alt={post.title}
          style={{
            width: 240,
            height: 240,
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            padding: {
              xs: 1,
              sm: 2,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
              textOverflow: "ellipsis",
            }}
          >
            {post.title}
          </Typography>
          <Typography>{post.excerpt}</Typography>
          {/* at bottom date */}
          <Box
            sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="text"
              size="small"
              color="primary"
              sx={{ marginLeft: 2 }}
            >
              {t("Leer más")}
            </Button>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        margin: 2,
        boxShadow: "0px 0px 1px rgb(0 0 0 / 50%);",
        cursor: "pointer",
        borderRadius: "10px",
        // hover effect
        "&:hover": {
          boxShadow: "0px 0px 5px rgb(0 0 0 / 50%);",
        },
      }}
      onClick={handleClick}
    >
      <CardMedia
        image={media_url(post.image)}
        title={post.title}
        sx={{
          height: {
            xs: 150,
            sm: 200,
            md: 240,
          },
          objectFit: "cover",
          textLOverflow: "ellipsis",
          textWhiteSpace: "nowrap",
          textWrap: "nowrap",
        }}
      />

      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 50,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {post.title}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 100,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textOverflow: "ellipsis",

              overflow: "hidden",
            }}
          >
            {post.excerpt}
          </Typography>
        </div>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button size="small" color="primary" variant="text">
          {t("Leer más")}
        </Button>
      </CardActions>
    </Card>
  );
};

const OnlineServiceCard = ({ icon, text, onClick }) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        borderRight: "1px solid #ccc",
        boxShadow: "0px 0px 1px rgb(0 0 0 / 50%)",
        // hover effect
        "&:hover": {
          boxShadow: "0px 0px 5px rgb(0 0 0 / 50%)",
        },
        borderRadius: "10px",
      }}
    >
      <CardMedia image={icon} title={text} sx={{ height: 150, width: 150 }} />

      <Typography variant="h6">{text}</Typography>
    </Card>
  );
};

const BlogSideBar = () => {
  const { snippets } = useSelector((state) => state.blog);

  return (
    <Box>
      {snippets
        .filter((s) => s.position === "main_page_side")
        .map((snippet) => (
          <div key={snippet.id}>
            <div
              key={snippet.id}
              dangerouslySetInnerHTML={{ __html: snippet.html }}
              style={{ margin: 10 }}
            />
            {snippet.script && (
              <script
                dangerouslySetInnerHTML={{ __html: snippet.script }}
              ></script>
            )}
          </div>
        ))}
    </Box>
  );
};
export { BlogPostCard, BlogSideBar };

export default BlogIndex;
