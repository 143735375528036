import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  departments: [],
  currentRealEstate: null,
  customerRealEstates: [],
  resultsCount: 0,
  pages: 1,
  cadastralPeriods: [],
  documentTypes: [],
  propertyCount: null,
  proceduresCount: null,
  propertyTypes: [],
  propertyUses: [],
  realEstateProcedures: [],
  ratings: [],
  ratingsValues: [],
  calls: [],
  sectors: [],
  parishes: [],
  selectedCallId: null,
  appointmentsError: null,
  loaded: false,
  loadingDetail: false,
  cadasterLoaded: false,
  realEstateDeleted: false,
  cadasterConfigLoaded: false,
  socketOpen: false,
  nextToCall: null,
  cadasterError: null,
  gotNewRealEstate: false,
  paginatedOptions: [25, 50, 100],
  reportGenerated: false,
  realEstateReports: [],
  cadasterFilters: {
    paginatedBy: 25,
    payment: "all",
    sectorId: "all",
    parishId: "all",
    page: 1,
    mainHouse: "all",
    useType: "all",
    hasDocument: "all",
    hasProcedure: "all",
    orderBy: "updated_desc",
    legal_aspect: "all",
    physical_aspect: "all",
    value_aspect: "all",
    certificate: "all",
    propertyType: "all",
    propertyUse: "all",
  },
  conformFilters: {
    state: "all",
  },
};

const slice = createSlice({
  name: "cadaster",
  initialState,
  reducers: {
    setCadasterConfig: (state, action) => {
      const {
        periods,
        property_types,
        property_uses,
        property_count,
        procedures_count,
        parishes,
        document_types,
        real_estate_procedures,
      } = action.payload;
      state.periods = periods;
      state.propertyTypes = property_types;
      state.propertyUses = property_uses;
      state.propertyCount = procedures_count;
      state.proceduresCount = procedures_count;
      state.documentTypes = document_types;
      state.realEstateProcedures = real_estate_procedures;
      state.parishes = _.concat(
        { value: "all", label: "all" },
        _.map(parishes, (_p) => {
          return {
            value: _p.id,
            label: _p.name,
            sectors: _.concat(
              { value: "all", label: "all" },
              _.map(_p.sectors, (_s) => {
                return { value: _s.id, label: _s.name };
              })
            ),
          };
        })
      );
      state.cadasterLoaded = true;

      state.cadasterConfigLoaded = true;
    },
    updateCadasterFilters: (state, action) => {
      const { ...news } = action.payload;

      state.cadasterFilters = {
        ...state.cadasterFilters,
        ...news,
      };
    },
    addCall(state, action) {
      const { realestate } = action.payload;
      var a = { ...realestate };
      var ac = _.find(state.calls, { id: realestate.id });

      if (ac) {
        ac = { ...ac };
        ac.lastCall = Date.now();
        ac.calls += 1;
        a = ac;
      } else {
        a.lastCall = Date.now();
        a.calls = 1;
      }

      state.calls = _.unionWith([a], state.calls, (a, b) => a.id === b.id);
      state.currentRealEstate = a;
    },
    removeCall(state, action) {
      const { callId } = action.payload;
      state.calls = _.reject(state.calls, { id: callId });
    },
    updateCall(state, action) {
      const { call } = action.payload;

      state.calls = _.map(state.calls, (_call) => {
        if (_call.id === call.id) {
          return call;
        }
        return _call;
      });
      state.currentRealEstate = call;
    },
    reCall(state, action) {
      const { currentRealEstate } = state;
      if (currentRealEstate) {
        var a = { ...currentRealEstate };
        a.calls += 1;
        a.lastCall = Date.now();
        state.currentRealEstate = a;
      }
    },
    refreshAppointment(state, action) {
      const { realestate } = action.payload;

      state.realEstates = _.map(state.realEstates, (_a) => {
        if (_a.id === realestate.id) {
          return realestate;
        }
        return _a;
      });
      state.nextToCall = _.find(
        state.realEstates,
        (_a) => !_a.calls && _a.state === "valid"
      );
    },
    clearCurrentCall(state, action) {
      state.currentRealEstate = null;
    },
    getRealEstates(state, action) {
      const { data, extra } = action.payload;
      console.log(extra);
      state.customerRealEstates = data;
      state.resultsCount = extra?.count || data.length;
      state.cadasterFilters.page = extra?.page || 1;
      state.pages = extra?.pages || 1;
      state.loaded = true;
      state.cadasterLoaded = true;
    },
    setAppointmentsError(state, payload) {
      const { error } = payload;
      state.appointmentsError = error;
    },
    notifySocketState(state, action) {
      const { socketOpen } = action.payload;
      state.socketOpen = socketOpen;
    },
    setCustomerRealEstates: (state, action) => {
      const { data, extra } = action.payload;

      state.customerRealEstates = data;
      state.cadasterLoaded = true;
      state.resultsCount = extra ? extra.count : data.length;
      state.propertyCount = extra ? extra.count : data.length;
    },
    setCurrentRealEstate: (state, action) => {
      state.currentRealEstate = action.payload;
      state.realEstateDeleted = false;
    },
    updateRealEstate: (state, action) => {
      const { data } = action.payload;
      state.customerRealEstates = _.map(state.customerRealEstates, (_r) => {
        if (_r.id === data.id) {
          return data;
        }
        return _r;
      });
      state.currentRealEstate = data;
      state.realEstateDeleted = false;
    },
    newRealEstate: (state, action) => {
      const { data } = action.payload;
      state.customerRealEstates = _.concat(state.customerRealEstates, data);
      state.currentRealEstate = data;
      state.realEstateDeleted = false;
      state.gotNewRealEstate = true;
    },
    refreshRealEstate: (state, action) => {
      state.currentRealEstate = action.payload;
      state.realEstateDeleted = false;
      state.customerRealEstates = _.map(state.customerRealEstates, (_r) => {
        if (_r.id === action.payload.id) {
          return action.payload;
        }
        return _r;
      });
    },
    setDeleted: (state, action) => {
      state.realEstateDeleted = action.payload;
    },
    setLoadingDetail: (state, action) => {
      state.loadingDetail = false;
      state.realEstateDeleted = false;
    },
    setCadasterElement: (state, action) => {
      Object.keys(action.payload).map((e) => {
        state[e] = action.payload[e];
      });
    },
  },
});

export const reducer = slice.reducer;

export const getCadasterConfig = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.cadaster.config, data);
  dispatch(slice.actions.setCadasterConfig(response.data.data));
};
export const updateCadasterFilters = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCadasterFilters(data));
};

export const searchRealEstate = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.search,
    data
  );
  dispatch(slice.actions.getRealEstates(response.data));
};

export const getRealEstates = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.list,
    data
  );

  if (response && response.data && response.data.res === 1) {
    if (!data.generate_report) {
      dispatch(slice.actions.getRealEstates(response.data));
    } else {
      dispatch(
        slice.actions.setCadasterElement({
          reportGenerated: true,
          realEstateReports: response.data.data,
        })
      );
      setTimeout(() => {
        dispatch(slice.actions.setCadasterElement({ reportGenerated: false }));
      }, 3000);
    }
  } else {
    dispatch(slice.actions.setCadasterError({ error: response.data.message }));
  }
};
export const setCustomerRealEstates = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.list,
    data
  );

  dispatch(slice.actions.setCustomerRealEstates(response.data));
};
export const updateCall = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCall(data));
};

export const setCurrentRealEstate = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentRealEstate(data));
};
export const getRealEstateById = (data) => async (dispatch) => {
  dispatch(slice.actions.setLoadingDetail(true));
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.detail,
    data
  );
  dispatch(slice.actions.updateRealEstate(response.data));
  dispatch(slice.actions.setLoadingDetail(false));
};
export const getUpdatedRealEstate = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.detail,
    data
  );

  dispatch(slice.actions.updateRealEstate(response.data));
};
export const refreshRealEstate = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.detail,
    data
  );

  dispatch(slice.actions.updateRealEstate(response.data));
};
export const updateRealEstate = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.search,
    data
  );
  dispatch(slice.actions.updateRealEstate(response.data));
};
export const newRealEstate = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.search,
    data
  );
  dispatch(slice.actions.newRealEstate(response.data));
};

export const changeRealEstateAttribute = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.update,
    data
  );
  dispatch(slice.actions.updateRealEstate(response.data));
};
export const deleteRealEstateDocuments = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.deleteDocument,
    data
  );
  dispatch(slice.actions.updateRealEstate(response.data));
};
export const deleteRealEstateRights = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.search,
    data
  );
};

export const deleteCurrentRealEstate = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.cadaster.realestate.delete,
    data
  );
  dispatch(slice.actions.setDeleted(response.data.res));
};
export const setCadasterElement = (data) => async (dispatch) => {
  dispatch(slice.actions.setCadasterElement(data));
};

export default slice;
