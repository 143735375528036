import { useTheme } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  ClickAwayListener,
  Container,
  Grow,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GroupButtonMenu from "src/components/custom/GroupButtonMenu";
import LoadingScreen from "src/components/LoadingScreen";
import Logo from "src/components/Logo";
import useBlog from "src/hooks/useBlog";
import { getCustomers, setCustomerById } from "src/slices/customers";
import { useDispatch, useSelector } from "src/store";

const BlogLayout = ({ children }) => {
  const { t } = useTranslation();
  var configSite = window["configSite"];
  var customerId = window["customer_id"];
  const { currentCustomer, customers } = useSelector(
    (state) => state.customers
  );
  const { loaded, loading, menu } = useSelector((state) => state.blog);
  const dispatch = useDispatch();
  const { getMainBlog } = useBlog();
  const initialized = React.useRef(false);
  const history = useHistory();
  const theme = useTheme();
  const [thisState, setThisState] = React.useState({
    currentActionMenu: null,
    currentElementId: null,
    currentTargetElement: null,
  });
  const handleMenuClick = (event, action) => {
    console.log("handleMenuClick", event);
    setThisState((pre) => ({
      ...pre,
      currentActionMenu: action,
      currentElementId: event.currentTarget.getAttribute("id"),
      currentTargetElement: event.currentTarget,
    }));
  };

  const handleCloseMenu = () => {
    setThisState({
      ...thisState,
      currentActionMenu: null,
      currentElementId: null,
      currentTargetElement: null,
    });
  };
  function HideOnScroll({ children }) {
    const trigger = useScrollTrigger();
    console.log("HideOnScroll", trigger);
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  React.useEffect(() => {
    if (!customers) {
      dispatch(getCustomers());
    }
  }, [customers]);

  React.useEffect(() => {
    if (customers && customers.length > 0 && customerId && !currentCustomer) {
      dispatch(setCustomerById(customerId));
    }
  }, [customers, customerId, currentCustomer]);

  React.useEffect(() => {
    if (currentCustomer && !loaded && !loading) {
      getMainBlog();
    }
  }, [loaded, loading, currentCustomer]);

  console.log(thisState);

  if (!loaded || !currentCustomer || !customers)
    return <LoadingScreen message={`Loading home page`} />;
  return (
    <Box sx={{ flexGrow: 1 }} id="blog-layout" key="blog-layout">
      <HideOnScroll>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/">
              <Logo />
            </Link>
            {menu && menu.length > 0 && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "block" },
                  paddingLeft: `${theme.spacing(2)} !important`,
                }}
              >
                <GroupButtonMenu
                  key={`menu-blog`}
                  items={menu.map((item) => {
                    if (!item.enabled) return null;
                    if (item.children) {
                      return {
                        label: item.label,
                        // color of appbar
                        color: "info",
                        variant: "text",
                        items: item.children.map((child) => ({
                          label: child.label,
                          icon: child.icon,
                          onClick: () => {
                            history.push(
                              child.url || `/page/${child.page.slug}`
                            );
                          },
                        })),
                      };
                    }
                    return {
                      label: item.label,
                      icon: item.icon,
                      color: "info",
                      variant: "text",
                      onClick: () => {
                        history.push(item.url || `/page/${item.page.slug}`);
                      },
                    };
                  })}
                />
              </Box>
            )}

            {thisState.currentActionMenu && thisState.currentTargetElement && (
              <Popper
                sx={{
                  zIndex: 1000,
                }}
                open={true}
                anchorEl={thisState.currentTargetElement}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "left bottom" : "left top",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => {
                          setThisState({
                            ...thisState,
                            currentActionMenu: null,
                            currentElementId: null,
                            currentTargetElement: null,
                          });
                        }}
                      >
                        <MenuList
                          id={`menu-options`}
                          autoFocusItem
                          variant="menu"
                        >
                          {thisState.currentActionMenu.children.map((item) => (
                            <MenuItem
                              key={`menu-${thisState.currentActionMenu.id}-${item.id}`}
                              value={item}
                              onClick={() => {
                                history.push(
                                  item.url || `/page/${item.page.slug}`
                                );
                                handleCloseMenu();
                              }}
                            >
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Container>{children}</Container>
      {/* footer  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 150,
          backgroundColor: "primary.main",
          color: "white",
          padding: 2,
          "& a": {
            color: "white",
            marginRight: 2,
            textDecoration: "none",
            "&:hover": { color: "white" },
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography>
            {"© "} {new Date().getFullYear()} {currentCustomer.name}
          </Typography>
          <Typography>{t("Desarrollado por")} </Typography>
          <a
            href={"https://gestionciudad.com"}
            target="_blank"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              height: 50,
              width: 160,
              backgroundColor: "white",
              borderRadius: 5,
              padding: 2,
            }}
          >
            <img
              src="https://gestionciudad.com/images/logo-light.svg"
              alt="Gestion Ciudad"
              style={{ width: 140, height: "auto" }}
            />
          </a>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {/* Terminos y condiciones link to /terms */}
          <Link to="/terms">
            <Typography color="white">{t("Términos y condiciones")}</Typography>
          </Link>
          {/* Politica de privacidad a /privacy */}
          <Link to="/privacy">
            <Typography>{t("Política de privacidad")}</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogLayout;
