import { lazy } from "react";
import AuthGuard from "src/components/AuthGuard";

export default [
  // {
  //   exact: true,
  //   guard: AuthGuard,
  //   path: '/:customerName/cadaster/realestate/new/',
  //   component: lazy(() =>
  //     import('src/views/arkadu/cadaster/forms/NewRealEstate')
  //   )
  // },
  {
    exact: true,
    guard: AuthGuard,
    path: "/:customerName/cadaster/realestate/edit/:id",
    component: lazy(() =>
      import("src/views/arkadu/cadaster/forms/NewRealEstate")
    ),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/:customerName/cadaster/realestate/detail/:realEstateId/",
    component: lazy(() => import("src/views/arkadu/cadaster/RealEstateView")),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: [
      "/:customerName/cadaster/realestate/:actionName/",
      "/:customerName/cadaster/realestate/:actionName/:id/",
      "/:customerName/cadaster/:tabName",
      "/:customerName/cadaster/",
    ],
    component: lazy(() => import("src/views/arkadu/cadaster/index")),
  },
];
