var path = window["APP_HOST_SERVER"];
const host = window["REACT_APP_HOST"];
if (process.env.NODE_ENV === "development") {
  path = window["DEV_HOST_SERVER"]
    ? window["DEV_HOST_SERVER"]
    : `devborg.${host}`;
}

const _server = `https://${path}`;

const dbName = "arkadu";
const wsserver =
  process.env.NODE_ENV === "development" ? `wss://${path}` : `wss://${path}`;

const _media_server =
  process.env.NODE_ENV === "development"
    ? `${_server}/djmedia/`
    : `${_server}/djmedia/`;

const _static_server =
  process.env.NODE_ENV === "development"
    ? `${_server}/djstatic/`
    : `${_server}/djstatic/`;

export function media_url(media) {
  return `${_server}/djmedia/${media}`.replace(
    "devborg.arkadu.com",
    "alcaldialosguayos.org"
  );
}
export function static_url(media) {
  return `${_static_server}${media}`;
}
export function api_url(url) {
  return `${_server}${url}?format=json`;
}

const configSite = {
  ...window["configSite"],
  urls: {
    currentCustomer: {
      add_user: `${_server}/api/currentCustomer/add/user/`,
      detail: `${_server}/api/currentCustomer/get-currentCustomer/`,
      list: `${_server}/api/v2/currentCustomer/list/`,
      report: `${_server}/api/currentCustomer/get-report/`,
    },
    geo: {
      country_list: `${_server}/api/geo/geo-data/`,
      search: `${_server}/api/geo/search/`,
    },
    global: {
      get_infodata: `${_server}/api/global/get-infodata/0/`,
    },
    notify: {
      get_posts: `${_server}/api/cus/notify/posts/currentCustomer/0/post/`,
      new_post: `${_server}/api/cus/notify/posts/currentCustomer/0/post/`,
    },
    shop: {
      cash: {
        deposit: `${_server}/api/shop/cash/deposit/new/`,
        get_cashier_cr: `${_server}/api/shop/cash/get-cashier-cr/`,
        get_cr_detail: `${_server}/api/shop/cash/registers/get/`,
        get_register: `${_server}/api/shop/cash/registers/list/`,
        registers_list: `${_server}/api/shop/cash/registers/list/`,
      },
      get_shop: `${_server}/api/shop/get-user-shop/`,
      get_user_items: `${_server}/api/shop/get-user-items/`,
      payments: `${_server}/api/currentCustomer/0/shop/`,
      teller: {
        cash_fund: `${_server}/api/v2/shop/teller/register/cashfund/`,
        restore_cash_funds: `${_server}/api/v2/shop/teller/register/cashfund/restore/`,
        cash_fund_delete: `${_server}/api/v2/shop/teller/register/cashfund/delete/`,
        cash_fund_detail: `${_server}/api/v2/shop/teller/register/cashfund/detail/`,
        close_register: `${_server}/api/v2/shop/teller/register/close/`,
        detail: `${_server}/api/v2/shop/teller/detail/`,
        list: `${_server}/api/v2/shop/teller/list/`,
        new: `${_server}/api/v2/shop/teller/new/`,
        new_register: `${_server}/api/v2/shop/teller/register/new/`,
        register: `${_server}/api/v2/shop/teller/register/`,
        register_list: `${_server}/api/v2/shop/teller/register/list/`,
        update: `${_server}/api/v2/shop/teller/update/`,
        editRegister: `${_server}/api/v2/shop/teller/register/edit/`,
      },
    },
    site: {
      config: `${_server}/api/config/site/`,
      countries: `${_server}/api/country/?format=json`,
      geo_data: `${_server}/api/geo/geo-data/`,
      idcards: `${_server}/api/idcard/?format=json`,
    },
    taxes: {
      close_declaration: `${_server}/api/taxes/declaration/close/`,
      get_declaration: `${_server}/api/taxes/get-declaration/`,
      get_taxes: `${_server}/api/taxes/get-user-taxes/`,
      update_incomes: `${_server}/api/taxes/update-incomes/`,
    },
    trans: {
      add_path: `${_server}/api/trans/add/{{lng}}/adx/`,
      get_sets: `${_server}/api/trans/get/set/`,
      load_path: `${_server}/api/trans/get/set/{{lng}}/adx/`,
    },
    ui: {
      apps: "/apps/",
      currentCustomer: {
        root: "/",
      },
      dashboard: "/dashboard/",
      dashboardRoutes: [],
      home: "/",
      login: "/login/",
      paths: {
        cadaster: "cadastral",
        corporate: "flows",
        taxes: "treasury",
        user: "profile",
      },
      user: {
        companies: "/profile/data/companies/",
        profile: "/profile/account/",
        root: "/profile/",
      },
    },
    user: {
      auth: `${_server}/auth/`,
      access_request: `${_server}/api/user/access/request/`,
      delete_mobilephone: `${_server}/api/user/mobilephone/delete/`,
      delete_user_object: `${_server}/api/user/delete-user-object/`,
      get_profile: `${_server}/api/user/get-profile/`,
      identity: `${_server}/api/v2/user/identity/`,
      login: `${_server}/api/user/access/login/`,
      new_mobilephone: `${_server}/api/user/mobilephone/new/`,
      new_user_object: `${_server}/api/user/new-user-object/`,
      phone: `${_server}/api/user/phone/?format=json`,
      request_access: `${_server}/user/smart-access/request/`,
      search: `${_server}/api/user/search/`,
      sms_login: `${_server}/api/user/phone-register/`,
      update_user_data: `${_server}/api/user/update/profile/`,
    },
    v2: {
      account: {
        addFund: `${_server}/api/v2/shop/useraccount/addFund/`,
        updateAvatar: `${_server}/api/v2/shop/useraccount/updateAvatar/`,
        addFundList: `${_server}/api/v2/shop/addFund/list/`,
        addFundOptions: `${_server}/api/v2/shop/useraccount/addFundOptions/`,
        addFundStats: `${_server}/api/v2/shop/addFund/stats/`,
        addFundStatsDownload: `${_server}/api/v2/shop/addFund/report/Download/`,
        deleteDeposit: `${_server}/api/v2/shop/deleteDeposit/`,
        deposit: `${_server}/api/v2/shop/deposit/`,
        detail: `${_server}/api/v2/shop/useraccount/detail/`,
        transactionDetail: `${_server}/api/v2/shop/useraccount/transactionDetail/`,
        activateTransactions: `${_server}/api/v2/shop/useraccount/activateTransactions/`,
        inactivateTransaction: `${_server}/api/v2/shop/useraccount/inactivateTransaction/`,
        downloadVoucher: `${_server}/api/v2/shop/useraccount/addFund/downloadVoucher/`,
        fund: {
          detail: `${_server}/api/v2/shop/addFund/detail/`,
          transfer: `${_server}/api/v2/shop/addFund/transfer/`,
          transferDetail: `${_server}/api/v2/shop/addFund/transferDetail/`,
          updateTransfer: `${_server}/api/v2/shop/addFund/updateTransfer/`,
        },
        reportPdf: {
          detail: `${_server}/api/v2/shop/fundReportPdf/detail/`,
          list: `${_server}/api/v2/shop/fundReportPdf/list/`,
          new: `${_server}/api/v2/shop/fundReportPdf/new/`,
          update: `${_server}/api/v2/shop/fundReportPdf/update/`,
          delete: `${_server}/api/v2/shop/fundReportPdf/delete/`,
        },
        customerAccounts: `${_server}/api/v2/shop/useraccount/customerAccounts/`,
        handle: `${_server}/api/v2/shop/handle/`,
        index: `${_server}/api/v2/shop/useraccount/`,
        customerList: `${_server}/api/v2/shop/useraccount/customerList/`,
        newAddFund: `${_server}/api/v2/shop/useraccount/addFund/new/`,
        paymentAccount: {
          bank_report_detail: `${_server}/api/v2/shop/paymentAccount/bankFundReport/detail/`,
          bankFundReport: `${_server}/api/v2/shop/paymentAccount/bankFundReport/`,
          delete_bank_report: `${_server}/api/v2/shop/paymentAccount/bankFundReport/delete/`,
          loadCsv: `${_server}/api/v2/shop/paymentAccount/load-csv/`,
        },
        extraIncome: {
          list: `${_server}/api/v2/shop/extraIncome/list/`,
          add: `${_server}/api/v2/shop/extraIncome/add/`,
          delete: `${_server}/api/v2/shop/extraIncome/delete/`,
          addCategory: `${_server}/api/v2/shop/extraIncome/addCategory/`,
        },
        adjustments: {
          stats: `${_server}/api/v2/shop/accountingAdjustments/stats/`,
        },
        product: `${_server}/api/v2/shop/product/`,
        resendEmail: `${_server}/api/v2/shop/useraccount/addFund/resendEmail/`,
        search: `${_server}/api/v2/shop/useraccount/search/`,
        search_products: `${_server}/api/v2/shop/product/search/`,
      },
      admin: {
        useraccount: {
          addfund: `${_server}/api/v2/adminSecure/shop/useraccount/addfund/`,
        },
      },
      bank: {
        banesco_clear_transfer: `${_server}/api/v2/bank/banesco/clearTransfer/`,
        banesco_transfer: `${_server}/api/v2/bank/banesco/transfer/`,
        banesco: {
          boton: {
            createPayment: `${_server}/api/v2/bank/banesco/boton/createPayment/`,
            checkPayment: `${_server}/api/v2/bank/banesco/boton/checkPayment/`,
          },
        },
        login: `${_server}/api/v2/bank/banesco/connect/`,
        service: {
          addEnvironment: `${_server}/api/v2/bank/service/addEnvironment/`,
          getEnvironments: `${_server}/api/v2/bank/service/getEnvironments/`,
          list: `${_server}/api/v2/bank/service/list/`,
          enabled: `${_server}/api/v2/bank/service/enabled/`,
          updateEnvironment: `${_server}/api/v2/bank/service/updateEnvironment/`,
        },
        transfer: {
          detail: `${_server}/api/v2/bank/transfer/detail/`,
        },
        bdv: {
          createPayment: `${_server}/api/v2/bank/bdv/createPayment/`,
          checkPayment: `${_server}/api/v2/bank/bdv/checkPayment/`,
        },
      },
      blog: {
        post: {
          list: `${_server}/api/v2/blog/post/`,
          detail: `${_server}/api/v2/blog/post/detail/`,
        },
      },
      cadaster: {
        reports: `${_server}/api/v2/cadastre/reports/`,
        add_document: `${_server}/api/v2/cadastre/realestate/add-document/`,
        add_photo: `${_server}/api/v2/cadastre/realestate/add-photo/`,
        add_plan: `${_server}/api/v2/cadastre/realestate/add-plan/`,
        config: `${_server}/api/v2/cadastre/config/`,
        document: `${_server}/api/v2/cadastre/realestate/document/`,
        updateDocument: `${_server}/api/v2/cadastre/realestate/updateDocument/`,
        handle: `${_server}/api/v2/cadastre/`,
        list: `${_server}/api/v2/cadastre/list/`,
        new_procedure: `${_server}/api/v2/cadastre/realestate/new-procedure/`,
        realestate: {
          change: `${_server}/api/v2/cadastre/realestate/change/`,
          customTax: `${_server}/api/v2/cadastre/realestate/customTax/`,
          delete: `${_server}/api/v2/cadastre/realestate/delete/`,
          deleteDocument: `${_server}/api/v2/cadastre/realestate/deleteDocument/`,
          detail: `${_server}/api/v2/cadastre/realestate/detail/`,
          changeStatement: `${_server}/api/v2/cadastre/realestate/changeStatement/`,
          list: `${_server}/api/v2/cadastre/realestate/list/`,
          search: `${_server}/api/v2/cadastre/realestate/`,
          update: `${_server}/api/v2/cadastre/realestate/update/`,
          transfer: `${_server}/api/v2/cadastre/realestate/transfer/`,
          reports: `${_server}/api/v2/cadastre/realestate/reports/`,
          downloadReport: `${_server}/api/v2/cadastre/realestate/downloadReport/`,
        },
      },
      certificate: {
        download: `${_server}/api/v2/cert/certificate/download/`,
        revoke: `${_server}/api/v2/cert/certificate/revoke/`,
        realestate: `${_server}/api/v2/cert/certificate/realestate/`,
        verify: `${_server}/api/v2/cert/certificate/verify/`,
        liquor: `${_server}/api/v2/cert/certificate/liquor/`,
        license_closed: `${_server}/api/v2/cert/certificate/licenseClosed/`,
        generate: `${_server}/api/v2/cert/certificate/generate/`,
      },
      config: `${_server}/api/v2/config/`,
      corporate: {
        appointment: {
          search: `${_server}/api/v2/corporate/appointment/search/`,
          update: `${_server}/api/v2/corporate/appointment/update/`,
          validate: `${_server}/api/v2/corporate/appointment/validate/`,
          getAvailable: `${_server}/api/v2/corporate/appointment/getAvailable/`,
          create: `${_server}/api/v2/corporate/appointment/create/`,
        },
        department: {
          add: `${_server}/api/v2/corporate/department/add/`,
          delete: `${_server}/api/v2/corporate/department/delete/`,
          detail: `${_server}/api/v2/corporate/department/detail/`,
          list: `${_server}/api/v2/corporate/department/`,
          update: `${_server}/api/v2/corporate/department/update/`,
        },
        holiday: {
          add: `${_server}/api/v2/corporate/holiday/add/`,
          delete: `${_server}/api/v2/corporate/holiday/delete/`,
          detail: `${_server}/api/v2/corporate/holiday/detail/`,
          list: `${_server}/api/v2/corporate/holiday/`,
          update: `${_server}/api/v2/corporate/holiday/update/`,
        },
        office: {
          add: `${_server}/api/v2/corporate/office/add/`,
          delete: `${_server}/api/v2/corporate/office/delete/`,
          detail: `${_server}/api/v2/corporate/office/detail/`,
          list: `${_server}/api/v2/corporate/office/`,
          update: `${_server}/api/v2/corporate/office/update/`,
        },
        procedure: `${_server}/api/v2/corporate/procedure/`,
        procedureAdmin: {
          add: `${_server}/api/v2/corporate/procedure/add/`,
          addRequirement: `${_server}/api/v2/corporate/procedure/addRequirement/`,
          availableRequirements: `${_server}/api/v2/corporate/procedure/availableRequirements/`,
          delete: `${_server}/api/v2/corporate/procedure/delete/`,
          deleteRequirement: `${_server}/api/v2/corporate/procedure/deleteRequirement/`,
          detail: `${_server}/api/v2/corporate/procedure/detail/`,
          list: `${_server}/api/v2/corporate/procedure/`,
          update: `${_server}/api/v2/corporate/procedure/update/`,
          updateRequirement: `${_server}/api/v2/corporate/procedure/updateRequirement/`,
        },
        requirement: {
          add: `${_server}/api/v2/corporate/requirement/add/`,
          delete: `${_server}/api/v2/corporate/requirement/delete/`,
          list: `${_server}/api/v2/corporate/requirement/`,
          update: `${_server}/api/v2/corporate/requirement/update/`,
        },
        schedule: {
          add: `${_server}/api/v2/corporate/schedule/add/`,
          addDay: `${_server}/api/v2/corporate/schedule/addDay/`,
          delete: `${_server}/api/v2/corporate/schedule/delete/`,
          deleteDay: `${_server}/api/v2/corporate/schedule/deleteDay/`,
          detail: `${_server}/api/v2/corporate/schedule/detail/`,
          list: `${_server}/api/v2/corporate/schedule/`,
          update: `${_server}/api/v2/corporate/schedule/update/`,
          updateDay: `${_server}/api/v2/corporate/schedule/updateDay/`,
        },
        staff: {
          list: `${_server}/api/v2/corporate/staff/`,
          logs: `${_server}/api/v2/corporate/staff/logs/`,
        },
        state: {
          add: `${_server}/api/v2/corporate/state/add/`,
          delete: `${_server}/api/v2/corporate/state/delete/`,
          detail: `${_server}/api/v2/corporate/state/detail/`,
          list: `${_server}/api/v2/corporate/state/`,
          update: `${_server}/api/v2/corporate/state/update/`,
        },
        user_appointment: `${_server}/api/v2/corporate/procedure/userAppointment/`,

        user_procedure: `${_server}/api/v2/corporate/procedure/userProcedure/`,
        userProcedure: {
          add: `${_server}/api/v2/corporate/userProcedure/add/`,
          adminList: `${_server}/api/v2/corporate/userProcedure/admin/`,
          changeState: `${_server}/api/v2/corporate/userProcedure/changeState/`,
          delete: `${_server}/api/v2/corporate/userProcedure/delete/`,
          detail: `${_server}/api/v2/corporate/userProcedure/detail/`,
          list: `${_server}/api/v2/corporate/userProcedure/`,
          update: `${_server}/api/v2/corporate/userProcedure/update/`,
          updatePayment: `${_server}/api/v2/corporate/userProcedure/updatePayment/`,
          updateRequirement: `${_server}/api/v2/corporate/userProcedure/updateRequirement/`,
          updateRequirementStatus: `${_server}/api/v2/corporate/userProcedure/updateRequirementStatus/`,
        },
      },
      customer: {
        add: `${_server}/api/v2/customer/add/`,
        categories: `${_server}/api/v2/customer/categories/`,
        changeImage: `${_server}/api/v2/customer/changeImage/`,
        checkShortname: `${_server}/api/v2/customer/checkShortname/`,
        detail: `${_server}/api/v2/customer/detail/`,
        getClientServices: `${_server}/api/v2/customer/getClientServices/`,
        list: `${_server}/api/v2/customer/list/`,
        owned: `${_server}/api/v2/customer/owned/`,
        stats: `${_server}/api/v2/customer/stats/`,
      },
      dinvoice: {
        create_invoice: `${_server}/api/v2/dinvoice/invoice/create/`,
        download_invoice: `${_server}/api/v2/dinvoice/invoice/download/`,
        print: `${_server}/api/v2/dinvoice/invoice/print/`,
      },
      erp: {
        handle: `${_server}/api/v2/erp/`,
      },
      eventads: {
        ad: {
          add: `${_server}/api/v2/eventads/ad/add/`,
          delete: `${_server}/api/v2/eventads/ad/delete/`,
          detail: `${_server}/api/v2/eventads/ad/detail/`,
          list: `${_server}/api/v2/eventads/ad/`,
          update: `${_server}/api/v2/eventads/ad/update/`,
        },
        adRequest: {
          add: `${_server}/api/v2/eventads/adRequest/add/`,
          addFile: `${_server}/api/v2/eventads/adRequest/addFile/`,
          addItem: `${_server}/api/v2/eventads/adRequest/addItem/`,
          changeState: `${_server}/api/v2/eventads/adRequest/changeState/`,
          delete: `${_server}/api/v2/eventads/adRequest/delete/`,
          delFile: `${_server}/api/v2/eventads/adRequest/delFile/`,
          delItem: `${_server}/api/v2/eventads/adRequest/delItem/`,
          detail: `${_server}/api/v2/eventads/adRequest/detail/`,
          downloadFile: `${_server}/api/v2/eventads/adRequest/downloadFile/`,
          list: `${_server}/api/v2/eventads/adRequest/`,
          update: `${_server}/api/v2/eventads/adRequest/update/`,
        },
        adValue: {
          add: `${_server}/api/v2/eventads/adValue/add/`,
          delete: `${_server}/api/v2/eventads/adValue/delete/`,
          detail: `${_server}/api/v2/eventads/adValue/detail/`,
          list: `${_server}/api/v2/eventads/adValue/`,
          update: `${_server}/api/v2/eventads/adValue/update/`,
        },
        advertiser: {
          add: `${_server}/api/v2/eventads/advertiser/add/`,
          delete: `${_server}/api/v2/eventads/advertiser/delete/`,
          detail: `${_server}/api/v2/eventads/advertiser/detail/`,
          list: `${_server}/api/v2/eventads/advertiser/`,
          update: `${_server}/api/v2/eventads/advertiser/update/`,
        },
      },
      global: {
        handle: `${_server}/api/v2/global/`,
        taxunit_ref: `${_server}/api/v2/global/taxunit/ref/`,
        exchange: {
          list: `${_server}/api/v2/global/exchange/`,
        },
      },
      help: {
        getByID: `${_server}/api/v2/help/topic/get/`,
        topic: `${_server}/api/v2/help/topic/`,
      },
      module: {
        list: "/api/v2/user/module/list/",
      },
      motor: {
        addDocument: `${_server}/api/v2/motor/vehicle/addDocument/`,
        approve_inscription: `${_server}/api/v2/motor/vehicle/approveInscription/`,
        delete_inscription: `${_server}/api/v2/motor/vehicle/deleteInscription/`,
        handle: `${_server}/api/v2/motor/`,
        inscription: `${_server}/api/v2/motor/vehicle/newInscription/`,
        exempt_inscription: `${_server}/api/v2/motor/vehicle/inscription/exempt/`,
        updateStatement: `${_server}/api/v2/motor/vehicle/updateStatement/`,
        resetStatement: `${_server}/api/v2/motor/vehicle/resetStatement/`,
        inscription_detail: `${_server}/api/v2/motor/vehicle/inscription/`,
        inscriptionByVehicle: `${_server}/api/v2/motor/vehicle/inscriptionByVehicle/`,
        inscription_identity: `${_server}/api/v2/motor/vehicle/inscription/identity/`,
        inscription_list: `${_server}/api/v2/motor/vehicle/inscriptions/`,
        options: `${_server}/api/v2/motor/vehicle/options/`,
        vehicle: `${_server}/api/v2/motor/vehicle/`,
        vinDecode: `${_server}/api/v2/motor/vehicle/vindecode/`,
      },
      notify: {
        email: {
          add: `${_server}/api/v2/notify/email/add/`,
          list: `${_server}/api/v2/notify/email/`,
        },
        message: {
          list: `${_server}/api/v2/notify/message/list/`,
          new: `${_server}/api/v2/notify/message/new/`,
          update: `${_server}/api/v2/notify/message/update/`,
          updateRecipientState: `${_server}/api/v2/notify/message/updateRecipientState/`,
        },
        posts: `${_server}/api/v2/notify/post/list/`,
      },
      poll: {
        handle: `${_server}/api/v2/poll/handle/`,
      },
      service: {
        handle: `${_server}/api/v2/service/handle/`,
        clients: `${_server}/api/v2/service/service_customer/clients/`,
        clients_report: `${_server}/api/v2/service/service_customer/report/`,
        delete_client: `${_server}/api/v2/service/service_customer/delete/`,
        detail: `${_server}/api/v2/service/service_customer/detail/`,
        myServices: `${_server}/api/v2/service/service_customer/list/`,
        new_client: `${_server}/api/v2/service/service_customer/new/`,
        transferClient: `${_server}/api/v2/service/service_customer/transfer/`,
        update_invoice: `${_server}/api/v2/service/service_customer/updateInvoice/`,
        resetInvoices: `${_server}/api/v2/service/service_customer/resetInvoices/`,
        addInvoiceFile: `${_server}/api/v2/service/service_customer/addInvoiceFile/`,
        downloadInvoiceFile: `${_server}/api/v2/service/service_customer/downloadInvoiceFile/`,
        sendInvoiceEmail: `${_server}/api/v2/service/service_customer/sendInvoiceEmail/`,
        requestLegalInvoice: `${_server}/api/v2/service/service_customer/requestLegalInvoice/`,
        attachLegalInvoice: `${_server}/api/v2/service/service_customer/attachLegalInvoice/`,
        downloadLegalInvoice: `${_server}/api/v2/service/service_customer/downloadLegalInvoice/`,
        customDiscount: `${_server}/api/v2/service/service_customer/customDiscount/`,
        sectorList: `${_server}/api/v2/service/service_customer/sectorList/`,
        paymentCollector: {
          add: `${_server}/api/v2/service/paymentCollector/add/`,
          addSector: `${_server}/api/v2/service/paymentCollector/addSector/`,
          detail: `${_server}/api/v2/service/paymentCollector/detail/`,
          list: `${_server}/api/v2/service/paymentCollector/`,
          removeSector: `${_server}/api/v2/service/paymentCollector/removeSector/`,
          statement: {
            add: `${_server}/api/v2/service/paymentCollector/addStatement/`,
            addFund: `${_server}/api/v2/service/paymentCollector/addFund/`,
            addReference: `${_server}/api/v2/service/paymentCollector/addStatementReference/`,
            close: `${_server}/api/v2/service/paymentCollector/closeStatement/`,
            deleteFund: `${_server}/api/v2/service/paymentCollector/deleteFund/`,
            detail: `${_server}/api/v2/service/paymentCollector/statement/`,
            list: `${_server}/api/v2/service/paymentCollector/statementLis/`,
          },
          updateSector: `${_server}/api/v2/service/paymentCollector/updateSector/`,
        },
        search: `${_server}/api/v2/service/service_customer/search/`,
        search_old: `${_server}/api/v2/service/service_customer/searchOld/`,
        serviceTypes: {
          addValue: `${_server}/api/v2/service/serviceType/addValue/`,
          deleteValue: `${_server}/api/v2/service/serviceType/deleteValue/`,
          editValue: `${_server}/api/v2/service/serviceType/editValue/`,
          list: `${_server}/api/v2/service/serviceType/`,
          priceHistory: `${_server}/api/v2/service/serviceType/priceHistory/`,
        },
        stats: `${_server}/api/v2/service/service_customer/stats/`,
        update: `${_server}/api/v2/service/service_customer/update/`,
        upload_file: `${_server}/api/v2/service/customersources/upload/`,
      },
      staff: {
        handle: `${_server}/api/v2/staff/`,
      },
      stamp: {
        handle: `${_server}/api/v2/stamp/handle/`,
        reports: `${_server}/api/v2/stamp/reports/`,
        downloadReport: `${_server}/api/v2/stamp/downloadReport/`,
        createUserOrder: `${_server}/api/v2/stamp/createUserOrder/`,
        addItemToUserOrder: `${_server}/api/v2/stamp/addItemToUserOrder/`,
      },
      supplier: {
        handle: `${_server}/api/v2/suppliers/handle/`,
      },
      planning: {
        handle: `${_server}/api/v2/planning/handle/`,
      },
      support: {
        state: {
          list: `${_server}/api/v2/support/state/list/`,
        },
        ticket: {
          comment: `${_server}/api/v2/support/ticket/comment/`,
          detail: `${_server}/api/v2/support/ticket/detail/`,
          list: `${_server}/api/v2/support/ticket/list/`,
          new: `${_server}/api/v2/support/ticket/add/`,
          options: `${_server}/api/v2/support/ticket/options/`,
          state: `${_server}/api/v2/support/ticket/state/`,
        },
      },
      taxes: {
        activity: {
          classifier: `${_server}/api/v2/taxes/activity/classifier/`,
          incomes: `${_server}/api/v2/taxes/activity/incomes/`,
          index: `${_server}/api/v2/taxes/activity/`,
        },
        declaration: {
          attachVat: `${_server}/api/v2/taxes/declaration/attachVat/`,
          createAdmin: `${_server}/api/v2/taxes/declaration/createAdmin/`,
          createDefinitive: `${_server}/api/v2/taxes/declaration/createDefinitive/`,
          getFileTypes: `${_server}/api/v2/taxes/declaration/getFileTypes/`,
          addFile: `${_server}/api/v2/taxes/declaration/addFile/`,
          update: `${_server}/api/v2/taxes/declaration/update/`,
          declaration: `${_server}/api/v2/taxes/declaration/`,
          delete: `${_server}/api/v2/taxes/declaration/delete/`,
          deleteFee: `${_server}/api/v2/taxes/declaration/deleteFee/`,
          deleteFile: `${_server}/api/v2/taxes/declaration/deleteFile/`,
          deleteVat: `${_server}/api/v2/taxes/declaration/deleteVat/`,
          detail: `${_server}/api/v2/taxes/declaration/detail/`,
          downloadVat: `${_server}/api/v2/taxes/declaration/downloadVat/`,
          downloadFile: `${_server}/api/v2/taxes/declaration/downloadFile/`,
          income: `${_server}/api/v2/taxes/declaration/income/`,
          list: `${_server}/api/v2/taxes/declaration/list/`,
          state: `${_server}/api/v2/taxes/declaration/state/`,
        },
        download_report: `${_server}/api/v2/taxes/report/licenseXls/`,
        generateReport: `${_server}/api/v2/taxes/report/generate/`,
        deleteReport: `${_server}/api/v2/taxes/report/delete/`,
        downloadReport: `${_server}/api/v2/taxes/report/download/`,
        reportList: `${_server}/api/v2/taxes/report/list/`,
        handle: `${_server}/api/v2/taxes/handle/`,
        last_period: `${_server}/api/v2/taxes/period/last/`,
        license: {
          add: `${_server}/api/v2/taxes/license/add/`,
          addActivity: `${_server}/api/v2/taxes/license/addActivity/`,
          addNote: `${_server}/api/v2/taxes/license/addNote/`,
          addNoteFile: `${_server}/api/v2/taxes/license/addNoteFile/`,
          assign: `${_server}/api/v2/taxes/license/assign/`,
          change: `${_server}/api/v2/taxes/license/change/`,
          changeActivity: `${_server}/api/v2/taxes/license/changeActivity/`,
          changeNote: `${_server}/api/v2/taxes/license/changeNote/`,
          detail: `${_server}/api/v2/taxes/license/detail/`,
          downloadCertificate: `${_server}/api/v2/cert/certificate/license/`,
          downloadList: `${_server}/api/v2/taxes/license/downloadList/`,
          downloadNoteFile: `${_server}/api/v2/taxes/license/downloadNoteFile/`,
          downloadPending: `${_server}/api/v2/taxes/license/download/pending/`,
          feeRequest: `${_server}/api/v2/taxes/license/feeRequest/`,
          feeRequestDelete: `${_server}/api/v2/taxes/license/feeRequest/delete/`,
          feeRevision: `${_server}/api/v2/taxes/license/feeRevision/`,
          feeRevisionDelete: `${_server}/api/v2/taxes/license/feeRevision/delete/`,
          list: `${_server}/api/v2/taxes/license/list/`,
          pendingPeriods: `${_server}/api/v2/taxes/license/pendingPeriods/`,
          pendingDefinitive: `${_server}/api/v2/taxes/license/pendingDefinitive/`,
          resetDeclarations: `${_server}/api/v2/taxes/license/resetDeclarations/`,
          revision: `${_server}/api/v2/taxes/license/revision/`,
          search: `${_server}/api/v2/taxes/license/search/`,
          sync: `${_server}/api/v2/taxes/license/sync/`,
          transfer: `${_server}/api/v2/taxes/license/transfer/`,
          transferToEmail: `${_server}/api/v2/taxes/license/transferToEmail/`,
          exempt: `${_server}/api/v2/taxes/license/exempt/`,
          unassign: `${_server}/api/v2/taxes/license/unassign/`,
          addExemption: `${_server}/api/v2/taxes/license/addExemption/`,
          legal: {
            detail: `${_server}/api/v2/taxes/license/legal/detail/`,
          },
          closed: {
            detail: `${_server}/api/v2/taxes/license/closed/detail/`,
          },
        },
        penalty: {
          add: `${_server}/api/v2/taxes/penalty/add/`,
          attach: `${_server}/api/v2/taxes/penalty/attach/`,
          change: `${_server}/api/v2/taxes/penalty/change/`,
          delete: `${_server}/api/v2/taxes/penalty/delete/`,
          detail: `${_server}/api/v2/taxes/penalty/detail/`,
          downloadAttach: `${_server}/api/v2/taxes/penalty/downloadAttach/`,
          list: `${_server}/api/v2/taxes/penalty/list/`,
        },
        period: {
          activities: `${_server}/api/v2/taxes/period/activities/`,
          addFee: `${_server}/api/v2/taxes/period/addFee/`,
          declarations: `${_server}/api/v2/taxes/period/declarations/`,
          deleteFee: `${_server}/api/v2/taxes/period/deleteFee/`,
          main: `${_server}/api/v2/taxes/period/`,
          pendingLicenses: `${_server}/api/v2/taxes/period/`,
          stats: `${_server}/api/v2/taxes/stats/`,
        },
        retention: {
          activities: `${_server}/api/v2/taxes/retention/activities/`,
          add: `${_server}/api/v2/taxes/retention/add/`,
          add_statement: `${_server}/api/v2/taxes/retention/statement/new/`,
          change_state: `${_server}/api/v2/taxes/retention/statement/changeState/`,
          delete: `${_server}/api/v2/taxes/retention/delete/`,
          loadCsv: `${_server}/api/v2/taxes/retention/statement/loadCsv/`,
          statement_detail: `${_server}/api/v2/taxes/retention/statement/detail/`,
          statement_list: `${_server}/api/v2/taxes/retention/statement/`,
          deleteFee: `${_server}/api/v2/taxes/retention/statement/deleteFee/`,
        },
      },
      translate: {
        index: `${_server}/api/v2/translate/transset/`,
        lang: `${_server}/api/v2/translate/transset/langs/`,
      },
      user: {
        address: `${_server}/api/v2/user/address/`,
        assisted: `${_server}/api/v2/user/user/assisted/`,
        change_email: `${_server}/api/v2/user/user/changeEmail/`,
        company: `${_server}/api/v2/user/company/`,
        company_update: `${_server}/api/v2/user/company/update/`,
        company_member: `${_server}/api/v2/user/company/member/`,
        companySearch: `${_server}/api/v2/user/company/search/`,
        document: {
          download: `${_server}/api/v2/user/document/download/`,
          list: `${_server}/api/v2/user/document/`,
        },
        // document: `${_server}/api/v2/user/document/`,
        folder: `${_server}/api/v2/user/folder/`,
        get_device_uuid: `${_server}/api/v2/user/device/getDevUID/`,
        identity: {
          base: `${_server}/api/v2/user/identity/`,
          checkIdentity: `${_server}/api/v2/user/identity/checkIdentity/`,
          delete: `${_server}/api/v2/user/identity/delete/`,
          new: `${_server}/api/v2/user/identity/new/`,
          setMain: `${_server}/api/v2/user/identity/setMain/`,
          update: `${_server}/api/v2/user/identity/update/`,
        },
        identityCompanies: `${_server}/api/v2/user/company/identityCompanies/`,
        identityFile: `${_server}/api/v2/user/identity/addFile/`,
        logout: `${_server}/api/v2/user/user/logout/`,
        phone: {
          delete: `${_server}/api/v2/user/phone/delete/`,
          new: `${_server}/api/v2/user/phone/new/`,
          update: `${_server}/api/v2/user/phone/update/`,
        },
        pinAuthorization: `${_server}/api/v2/user/user/pinAuthorization/`,
        role: {
          addPermissions: `${_server}/api/v2/user/role/addPermissions/`,
          deletePermission: `${_server}/api/v2/user/role/deletePermission/`,
          detail: `${_server}/api/v2/user/role/detail/`,
          list: `${_server}/api/v2/user/role/`,
          options: `${_server}/api/v2/user/role/options/`,
        },
        search: `${_server}/api/v2/user/user/search/`,
        setPin: `${_server}/api/v2/user/user/setPin/`,
        update: `${_server}/api/v2/user/user/update/`,
        userAddresses: `${_server}/api/v2/user/address/userAddresses/`,
        userIdentities: `${_server}/api/v2/user/identity/userIdentities/`,
      },
    },
    webSocket: {
      appointment_monitor: `${wsserver}/ws/corporate/appointments/`,
      bank: `${wsserver}/ws/bank/`,
      customer: `${wsserver}/ws/customer/`,
      global: `${wsserver}/ws/global/`,
      shop: `${wsserver}/ws/shop/manager/`,
      taxes: `${wsserver}/ws/cortax/`,
      urban_cleaning: `${wsserver}/ws/uc/`,
      user: `${wsserver}/ws/user/`,
      user_account: `${wsserver}/ws/shop/account/`,
      motorFix: `${wsserver}/ws/motor/fix/`,
    },
  },
};

export default configSite;

export { dbName, _server as server, wsserver as ws_server };
